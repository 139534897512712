import styled from 'styled-components';

export const SlideStyles = styled.div`
  .container-slide {
    position: relative;
  }

  .carousel-variable-width .slick-slide {
    margin-right: 26px;
  }

  .slick-arrow:before {
    display: none !important;
  }

  .slick-arrow-btn {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%);
    border: 1px solid transparent !important;
    background: var(--bg-content) !important;
    width: 50px !important;
    height: 50px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer !important;
    user-select: none;
    box-shadow: 0px 4px 32px rgba(255, 138, 209, 0.32);
    font-size: 16px !important;
    z-index: 2;
    color: var(--text-primary) !important;
  }

  .slick-prev {
    &__custom {
      left: -12px !important;
    }
  }
  .slick-next {
    &__custom {
      right: -12px !important;
    }
  }

  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-prev:focus,
  .ant-carousel .slick-next:focus {
    background: var(--bg-content) !important;
  }

  .ant-carousel .slick-list .slick-slide {
    pointer-events: all;
  }

  .slide-shadow-item {
    .slick-slider {
      margin: -20px -12px;
    }
  }

  .slick-disabled {
    display: none !important;
  }

  .highlight-arrow {
    .slick-arrow-btn {
      background: var(--primary) !important;
      color: #fff !important;
      width: 40px !important;
      height: 60px !important;
    }
    .slick-next__custom {
      border-radius: 8px 0px 0px 8px;
      right: 10px !important;
    }

    .slick-prev__custom {
      left: 10px !important;
      border-radius: 8px 0px 0px 8px;
    }
    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover,
    .ant-carousel .slick-prev:focus,
    .ant-carousel .slick-next:focus {
      background: var(--primary) !important;
    }
  }
`;
