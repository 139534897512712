import styled from 'styled-components';
import { respondTo } from 'utils/variables';

export const ResidentialItemStyles = styled.div`
  .residential-item {
    &__cover-wrapper {
      position: relative;
    }

    &__cover {
      height: 200px;
      object-fit: cover;
      width: 100%;
    }

    &__label {
      float: right;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    &__banner {
      width: 100%;
      border-radius: unset !important;
    }

    &__facilities {
      font-size: 16px;
      ${respondTo.md`
        font-size: 14px;
    `};

      .anticon {
        margin-left: 8px;
        color: var(--color-grey-l3);
      }
    }

    &__cover-bottom {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }

    &__new {
      border-radius: 12px 0px 8px;
      position: absolute;
      top: 0px;
      left: 0px;
      padding: 4px 14px !important;
    }
  }

  .ant-card-meta-title {
    font-weight: bold;
    font-size: 18px;
    ${respondTo.md`
        font-size: 16px;
    `};
  }

  .residential-address {
    font-size: 16px;
    ${respondTo.md`
        font-size: 14px;
    `};
  }
`;
