import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TagCustomWrapper from './styles';

const TagCustom = ({
  color,
  backgroundColor,
  className,
  IconCPN,
  iconProps,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <TagCustomWrapper
      className={`${className} tag-custom`}
      style={{ backgroundColor, color }}
    >
      {IconCPN && <IconCPN {...iconProps} />}
      <div>{t(title)}</div>
    </TagCustomWrapper>
  );
};

TagCustom.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  IconCPN: PropTypes.any,
  iconProps: PropTypes.object,
};

export default TagCustom;
