import Head from 'next/head';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { SEO_VAR } from 'configs/seo';
import { CANONICAL_OVERRIDE_KEY } from 'configs/localData';

const HeadSeo = ({
  item = {},
  paramsTitle,
  paramsDesc,
  image,
  hiddenMetaTitleDesc,
  canonicalLink,
  structuredData,
}) => {
  const { t } = useTranslation(['seo']);
  const titleSeo = t(item.title, paramsTitle) || SEO_VAR.title;
  const descriptionSeo = t(item.description, paramsDesc) || SEO_VAR.description;
  const imageSeo = image || item.image || SEO_VAR.image;

  return (
    <Head>
      <title>{titleSeo}</title>
      <meta property="og:image" content={imageSeo} />
      <meta itemProp="image" content={imageSeo} />
      <link rel="apple-touch-icon" href="/app-touch-icon.png" />
      {hiddenMetaTitleDesc ? null : (
        <>
          <meta name="description" content={descriptionSeo} />
          <meta itemProp="title" content={titleSeo} />
          <meta itemProp="description" content={descriptionSeo} />
          <meta property="og:title" content={titleSeo} />
          <meta property="og:site_name" content={titleSeo} />
          <meta property="og:description" content={descriptionSeo} />

          <meta property="twitter:title" content={titleSeo} />
          <meta property="twitter:description" content={descriptionSeo} />
        </>
      )}
      {!!canonicalLink && (
        <link
          rel="canonical"
          href={canonicalLink}
          key={CANONICAL_OVERRIDE_KEY}
        />
      )}
      {!!structuredData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
          key="structured-data"
        />
      )}
    </Head>
  );
};

HeadSeo.propTypes = {
  item: PropTypes.object,
  paramsTitle: PropTypes.object,
  paramsDesc: PropTypes.object,
  image: PropTypes.string,
  hiddenMetaTitleDesc: PropTypes.bool,
  canonicalLink: PropTypes.string,
  structuredData: PropTypes.object,
};

export default HeadSeo;
