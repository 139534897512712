import styled from 'styled-components';
import React from 'react';

interface Props extends React.HTMLProps<HTMLElement> {
  fullWidth?: boolean;
  isExistOnSlide?: boolean;
}

const CardItem = ({
  fullWidth = true,
  isExistOnSlide = true,
  ...props
}: Props) => (
  <CardItemWrapper
    fullWidth={fullWidth}
    isExistOnSlide={isExistOnSlide}
    {...props}
  />
);

export default CardItem;

export const CardItemWrapper = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '400px')};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '82vw')};
  padding: ${({ isExistOnSlide }) => (isExistOnSlide ? '20px 7px' : '')};
  height: 100%;

  .ant-card-body {
    min-height: ${({ isExistOnSlide }) => (isExistOnSlide ? '125px' : 'unset')};
  }

  .ant-card {
    height: 100%;
  }
`;
