import PropTypes from 'prop-types';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { centresSelectors } from '@redux/centres/selectors';

const NextArrow = ({ onClick, className, customOnClick }) => {
  const onNext = () => {
    customOnClick?.();
    onClick();
  };
  const isLoading = useSelector(centresSelectors.getLoading);

  return (
    <div
      className={`${className} slick-next__custom slick-arrow-btn`}
      role="presentation"
      onClick={onNext || onClick}
    >
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
          }}
        >
          <Spin spinning={isLoading} />
        </div>
      ) : (
        <ArrowRightOutlined />
      )}
    </div>
  );
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
  customOnClick: PropTypes.func,
  className: PropTypes.string,
};

export default NextArrow;
