import PropTypes from 'prop-types';
import { ArrowLeftOutlined } from '@ant-design/icons';

const PrevArrow = ({ onClick, className, customOnClick }) => (
  <div
    className={`${className} slick-prev__custom slick-arrow-btn`}
    role="presentation"
    onClick={customOnClick || onClick}
  >
    <ArrowLeftOutlined />
  </div>
);

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  customOnClick: PropTypes.func,
};

export default PrevArrow;
