import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { isMobile } from 'utils/tools';
import { SlideStyles } from './styles';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';

interface Props {
  children: React.ReactNode;
  className?: string;
  onNextClick?: () => void;
  onPrevClick?: () => void;
  variableWidth?: boolean;
  showDots?: boolean;
  isShadowItem?: boolean;
  highlightArrow?: boolean;
  onActiveTabChange?: () => void;
  isShowArrowInMobile: boolean;
  sizes?: string;
  [key: string]: unknown;
}

const Slide = forwardRef(
  (
    {
      children,
      className,
      variableWidth = true,
      showDots = false,
      isShadowItem,
      highlightArrow,
      onNextClick,
      onPrevClick,
      isShowArrowInMobile = false,
      ...key
    }: Props,
    ref,
  ) => {
    const carouselRef = useRef<CarouselRef>();

    useImperativeHandle(
      ref,
      () => ({
        getRef: () => carouselRef,
      }),
      [],
    );

    return (
      <SlideStyles>
        <div
          className={`${className || ''} ${
            isShadowItem ? 'slide-shadow-item' : ''
          } container-slide ${highlightArrow ? 'highlight-arrow' : ''}`}
        >
          <Carousel
            lazyLoad="ondemand"
            variableWidth={variableWidth}
            dots={showDots}
            ref={carouselRef}
            infinite={false}
            arrows={!(isMobile() && isShowArrowInMobile === false)}
            waitForAnimate
            className={variableWidth ? 'carousel-variable-width' : ''}
            nextArrow={<NextArrow customOnClick={onNextClick} />}
            prevArrow={<PrevArrow customOnClick={onPrevClick} />}
            {...key}
          >
            {React.Children.map(children, (child, idx) => (
              <div key={String(idx)}>{child}</div>
            ))}
          </Carousel>
        </div>
      </SlideStyles>
    );
  },
);

export default Slide;
