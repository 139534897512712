import NextImage from '@uikit/commons/NextImage';
import styled from 'styled-components';
import { checkImageDomain } from 'utils/tools';
import imageDomainWhitelist from 'configs/imageDomainWhitelist';
import { DEFAULT_CENTRE_COVER } from 'configs/localData';

const CoverImageRatioStyles = styled.div`
  &.cover-image__ratio {
    .cover-image__image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.cover-image__default {
    .cover-image__image {
      height: ${({ height }) => `${height}px`};
      object-fit: cover;
      width: 100%;
    }
  }
`;

interface Props {
  src?: string;
  fullWidth?: boolean;
  height?: number;
  ratioClassName?: string;
  sizes?: string;
}

const CoverImageRatio = ({
  src,
  fullWidth,
  height = 200,
  ratioClassName = 'ratio-2-1',
  sizes,
}: Props) => (
  <CoverImageRatioStyles
    height={height}
    className={
      fullWidth
        ? `${ratioClassName} cover-image__ratio`
        : 'cover-image__default'
    }
  >
    {checkImageDomain(src, imageDomainWhitelist) ? (
      <div className="cover-image__image">
        <NextImage
          src={src}
          layout="fill"
          className="object-cover"
          fallbackSrc={DEFAULT_CENTRE_COVER}
          sizes={sizes}
        />
      </div>
    ) : (
      <img
        alt="kindicare"
        src={src ?? DEFAULT_CENTRE_COVER}
        className="cover-image__image"
      />
    )}
  </CoverImageRatioStyles>
);

export default CoverImageRatio;
