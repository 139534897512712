import { useInView } from 'react-intersection-observer';
import { FunctionComponent, useRef } from 'react';
import { omit } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { RandomAdProps } from '@redux/advertisement/types';
import { DEFAULT_CENTRES_DELAY_IMPRESSION_TRACKING } from 'configs/constants';

interface TrackingOptions {
  threshold: number;
  rootMargin?: string;
  inView?: boolean;
  root?: Element | HTMLElement;
  initialInView?: boolean;
}

type DataAction = {
  loggingData?: TrackingEvent;
};

interface ImpressionEventTracking extends AdsCardProps, RandomAdProps {
  impression?: DataAction;
  delayImpression?: number;
  [key: string]: string | DataAction | object | boolean | number;
}

const withTrackingImpression = (
  WrappedComponent: FunctionComponent<ImpressionEventTracking>,
) =>
  function (props: ImpressionEventTracking) {
    const dispatch = useDispatch();
    const timeoutTrackingRef = useRef(null);
    const rootMargin = props?.rootMargin?.toString() || '0px 0px';

    const trackingOption: TrackingOptions = {
      threshold: 1,
      rootMargin,
    };
    const { ref, inView } = useInView({
      ...trackingOption,
    });
    const { delayImpression } = props;
    if (inView) {
      timeoutTrackingRef.current = setTimeout(() => {
        const impressionEvent = props?.impression?.loggingData;
        if (impressionEvent) {
          dispatch(trackingEvent(omit(impressionEvent, '_typename')));
        }
      }, delayImpression || DEFAULT_CENTRES_DELAY_IMPRESSION_TRACKING);
    } else {
      clearTimeout(timeoutTrackingRef.current);
    }

    return (
      <div className="h-full width-99" ref={ref}>
        <WrappedComponent {...props} />
      </div>
    );
  };

export default withTrackingImpression;
