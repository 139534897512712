import { Card, Space } from 'antd';
import TagCustom from '@uikit/commons/TagCustom';
import {
  BathroomsOutlineIcon,
  BedroomsOutlineIcon,
  CarSpacesOutlineIcon,
} from '@uikit/commons/SVGIcons';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { IResidential } from 'types/residential';
import CoverImageRatio from '@uikit/items/CoverImageRatio';
import CardItem from '@uikit/items/CardItem';
import { ResidentialItemStyles } from './styles';

const { Meta } = Card;

interface Props {
  item?: IResidential;
  fullWidth?: boolean;
  isExistOnSlide?: boolean;
}

const ResidentialItem = ({
  item = {},
  fullWidth = true,
  isExistOnSlide = true,
}: Props) => {
  const dispatch = useDispatch();

  const handleClickItem = () => {
    item?.listingUrl &&
      dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.domainItemViewed,
          eventData: { listingUrl: item.listingUrl },
        }),
      );
  };

  return (
    <CardItem fullWidth={fullWidth} isExistOnSlide={isExistOnSlide}>
      <a
        href={item?.listingUrl}
        target="_blank"
        rel="noreferrer"
        onClick={handleClickItem}
      >
        <ResidentialItemStyles>
          <Card
            hoverable
            bordered={false}
            className="shadow"
            cover={
              <div className="residential-item__cover-wrapper">
                <CoverImageRatio fullWidth={fullWidth} src={item.image} />
                {item.labels && (
                  <TagCustom
                    backgroundColor="#36BF57"
                    color="#fff"
                    className="residential-item__new"
                    title={item.labels}
                  />
                )}

                <div className="residential-item__cover-bottom">
                  {item.type && (
                    <TagCustom
                      backgroundColor="#EDF9F0"
                      color="#36BF57"
                      className="residential-item__label"
                      title={item.type}
                    />
                  )}
                  {item.banner && (
                    <img
                      className="residential-item__banner"
                      src={item.banner}
                      alt="domain"
                    />
                  )}
                </div>
              </div>
            }
          >
            <Meta className="mb-10" title={item?.headline} />

            <div className="residential-address mb-10 text-secondary ellipsis-t">
              {item.address}
            </div>

            <Space
              wrap
              size={20}
              className="residential-item__facilities text-secondary"
            >
              <div>
                <span>{item.bedrooms || 0}</span>
                <BedroomsOutlineIcon />
              </div>

              <div>
                <span>{item.bathrooms || 0}</span>
                <BathroomsOutlineIcon />
              </div>

              <div>
                <span>{item.carspaces || 0}</span>
                <CarSpacesOutlineIcon />
              </div>
            </Space>
          </Card>
        </ResidentialItemStyles>
      </a>
    </CardItem>
  );
};

export default ResidentialItem;
